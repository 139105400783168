import { createSlice } from '@reduxjs/toolkit';
import { SERVICE_URL } from 'config.js';
import axios from 'axios';

const initialState = {
  payments: [],
  pageCount: 0,
  pageIndex: 0,
  loading: false,
};

const paymentsSlice = createSlice({
  name: 'payments',
  initialState,
  reducers: {
    receiveService(state, action) {
      const { payments, pageCount, loading } = action.payload;
      state.payments = payments;
      state.pageCount = pageCount;
      state.loading = loading;
    },
    setLoading(state, action) {
      state.loading = action.payload;
    },
  },
});
const { setLoading, receiveService } = paymentsSlice.actions;

export const getPayments =
  ({ term, sortBy, pageSize, pageIndex }) =>
  async (dispatch) => {
    dispatch(setLoading(true));
    const response = await axios.get(`${SERVICE_URL}/payments`, { params: { term, sortBy, pageSize, pageIndex } });
    const { items: payments, pageCount } = response.data;
    dispatch(receiveService({ payments, pageCount, loading: false, pageIndex }));
  };

export const createPayment =
  ({ sortBy, pageSize, pageIndex, item }) =>
  async (dispatch) => {
    dispatch(setLoading(true));
    const response = await axios.post(`${SERVICE_URL}/payments`, { sortBy, pageSize, pageIndex, item });
    const { items: payments, pageCount } = response.data;
    dispatch(receiveService({ payments, pageCount, loading: false, pageIndex }));
  };

export const updatePayment =
  ({ sortBy, pageSize, pageIndex, item }) =>
  async (dispatch) => {
    dispatch(setLoading(true));
    const response = await axios.put(`${SERVICE_URL}/payments`, { sortBy, pageSize, pageIndex, item });
    const { items: payments, pageCount } = response.data;
    dispatch(receiveService({ payments, pageCount, loading: false, pageIndex }));
  };
export const deletePayment =
  ({ sortBy, pageSize, pageIndex, ids }) =>
  async (dispatch) => {
    const response = await axios.delete(`${SERVICE_URL}/payments`, { sortBy, pageSize, pageIndex, ids });
    const { items: payments, pageCount } = response.data;
    dispatch(receiveService({ payments, pageCount, loading: false, pageIndex }));
  };

const paymentsReducer = paymentsSlice.reducer;

export default paymentsReducer;
