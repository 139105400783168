import React, { useEffect, useState } from "react";
import Select, { components } from "react-select";

export const SelectFloatingLabel = ({ label, onChange, items, className }) => {
    const options = items.map((item) => ({
      value: item.courseId || item.id,
      label: item.course ? item.course.name : item.name,
    }));
    const [value, setValue] = useState();
    useEffect(() => {
      if (value !== undefined) {
        onChange(value);
      }
    }, [value, onChange]);
    const Control = ({ children, ...props }) => {
      return (
        <components.Control {...props} className="form-floating">
          {children}
          <label>{label}</label>
        </components.Control>
      );
    };

    return (
      <Select
        classNamePrefix="react-select"
        className={className}
        components={{ Control }}
        options={options}
        value={value}
        onChange={setValue}
        placeholder=""
      />
    );
  };

  // validate jwt, if jwt does not meet the criteria, return true, !jwt || jwt === '' || (typeof jwt === 'object' && Object.keys(jwt).length === 0)
  export const validateJwt = (jwt) => {
    return jwt && typeof jwt === 'string' && jwt.trim() !== '' && jwt !== '{}' && jwt.constructor === String;
  };
  