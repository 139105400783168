import { createSlice, createAsyncThunk } from '@reduxjs/toolkit';
import { SERVICE_URL } from 'config.js';
import axios from 'axios';
import { getAxiosConfig } from 'auth/authService';

const initialState = {
  subscriptions: [],
  pageCount: 0,
  pageIndex: 0,
  loading: false,
  error: null,
};

// Async thunks
export const getSubscriptions = createAsyncThunk(
  'subscriptions/getSubscriptions',
  async ({ term, sortBy, pageSize, pageIndex }, { rejectWithValue }) => {
    try {
      const params = new URLSearchParams();
      if (term) params.append('term', term);
      if (sortBy) {
        params.append('sortBy', `${sortBy.id},${sortBy.desc ? 'desc' : 'asc'}`);
      }
      params.append('pageSize', pageSize.toString());
      params.append('pageIndex', pageIndex.toString());

      const response = await axios.get(`${SERVICE_URL}/subscriptions`, {
        params,
        ...getAxiosConfig(),
      });
      return response.data;
    } catch (error) {
      return rejectWithValue(error.response.data);
    }
  }
);

export const createSubscription = createAsyncThunk(
  'subscriptions/createSubscription',
  async ({ sortBy, pageSize, pageIndex, item }, { rejectWithValue }) => {
    try {
      const response = await axios.post(`${SERVICE_URL}/subscriptions`, item, {
        params: { sortBy, pageSize, pageIndex },
        ...getAxiosConfig(),
      });
      return response.data;
    } catch (error) {
      return rejectWithValue(error.response.data);
    }
  }
);

export const updateSubscription = createAsyncThunk(
  'subscriptions/updateSubscription',
  async ({ sortBy, pageSize, pageIndex, item }, { rejectWithValue }) => {
    try {
      const response = await axios.put(`${SERVICE_URL}/subscriptions/${item.id}`, item, {
        params: { sortBy, pageSize, pageIndex },
        ...getAxiosConfig(),
      });
      return response.data;
    } catch (error) {
      return rejectWithValue(error.response.data);
    }
  }
);

export const deleteSubscription = createAsyncThunk(
  'subscriptions/deleteSubscription',
  async ({ sortBy, pageSize, pageIndex, ids }, { rejectWithValue }) => {
    try {
      const response = await axios.delete(`${SERVICE_URL}/subscriptions`, {
        params: { sortBy, pageSize, pageIndex, ids },
        ...getAxiosConfig(),
      });
      return response.data;
    } catch (error) {
      return rejectWithValue(error.response.data);
    }
  }
);

const subscriptionsSlice = createSlice({
  name: 'subscriptions',
  initialState,
  reducers: {},
  extraReducers: (builder) => {
    builder
      .addCase(getSubscriptions.pending, (state) => {
        state.loading = true;
        state.error = null;
      })
      .addCase(getSubscriptions.fulfilled, (state, action) => {
        state.loading = false;
        state.subscriptions = action.payload.subscriptions;
        state.pageCount = action.payload.pageCount;
        state.pageIndex = action.payload.pageIndex;
      })
      .addCase(getSubscriptions.rejected, (state, action) => {
        state.loading = false;
        state.error = action.payload;
      })
      // Handle other async thunks similarly
      .addMatcher(
        (action) => action.type.endsWith('/pending'),
        (state) => {
          state.loading = true;
          state.error = null;
        }
      )
      .addMatcher(
        (action) => action.type.endsWith('/fulfilled'),
        (state) => {
          state.loading = false;
        }
      )
      .addMatcher(
        (action) => action.type.endsWith('/rejected'),
        (state, action) => {
          state.loading = false;
          state.error = action.payload;
        }
      );
  },
});

export default subscriptionsSlice.reducer;